import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useDark, useToggle } from "@vueuse/core";
export default {
  __name: 'App',
  setup(__props) {
    useDark({
      selector: 'html',
      attribute: 'class',
      valueDark: 'dark',
      valueLight: 'light'
    });
    useDark({
      onChanged(dark) {
        useToggle(dark);
      }
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_router_view)]);
    };
  }
};