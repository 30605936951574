import {createRouter, createWebHistory} from "vue-router";
import {unauthorized} from "@/net";


const routes = [
    {
        path: '/',
        name: 'welcome',
        component: () => import('@/views/WelcomeView'),
        children: [
            {
                path: '',
                name: 'welcome-login',
                component: () => import('@/views/welcome/LoginPage')
            },
            {
                path: 'register',
                name: 'welcome-register',
                component: () => import('@/views/welcome/RegisterPage')
            },
            {
                path: 'reset',
                name: 'welcome-reset',
                component: () => import('@/views/welcome/ResetPage')
            },
        ]
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('@/views/IndexView'),
        children: [
            {
                path: '/add',
                name: 'add',
                component: () => import('@/views/unfiledData/add')
            },
            {
                path: '/query',
                name: 'query',
                component: () => import('@/views/unfiledData/query')
            },
            {
                path: '/setname',
                name: 'setname',
                component: () => import('@/views/setting/setname')
            },
            {
                path: '/setrole',
                name: 'setrole',
                component: () => import('@/views/setting/setrole')
            },
            {
                path: '/output',
                name: 'output',
                component: () => import('@/views/filedData/output')
            },
            {
                path: '/projectQuery',
                name: 'projectQuery',
                component: () => import('@/components/projectQuery')
            },
            {
                path: '/projectform',
                name: 'projectform',
                component: () => import('@/components/projectform')
            },
        ]
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const isUnauthorized = unauthorized()
    //如果用户已经登录请求登录带welcome开头的界面
    if (to.name.startsWith('welcome-') && !isUnauthorized) {
        next('/index')
        //    用户没有登录情况下请求登录index界面
    } else if (to.fullPath.startsWith('/index') && isUnauthorized) {
        //        回登录界面
        next('/')
    } else {
        //正常情况
        next()
    }
})

export default router