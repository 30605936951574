import {createApp} from 'vue'
import {createPinia} from "pinia";
import createPersistedState from "pinia-plugin-persistedstate"
import App from './App.vue'
import router from "@/router";
import axios from "axios";
import 'element-plus/theme-chalk/dark/css-vars.css'

//处理ResizeObserver loop limit exceeded
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    }
}
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
}
//本机设计时
// axios.defaults.baseURL = 'http://localhost:8080'
// 部署服务器
axios.defaults.baseURL = 'https://labproject2023.online:8080'

const app = createApp(App)
const pinia = createPinia()
pinia.use(createPersistedState)


app.use(router)
app.use(pinia)

app.mount('#app')
