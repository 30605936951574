import {defineStore} from "pinia";
import {get, post} from "@/net";
import {ElMessage} from "element-plus";

const appuser = defineStore("appuser", {
    state: () => ({
        form: [],
        editForm: [],
        user: {}
    }),
    // persist:true,
    persist:{
        storage:sessionStorage,
        //user数据持久
        paths:['user'],
    },
    actions: {
        async fetchTableData() {
            // 发起异步请求从数据库中获取数据
            await get('/api/index/getlist', data => {
                this.form = data;
            })
        },
        setEditForm(form) {
            this.editForm = form
        },
        async saveEditName(data) {
            // 发起保存编辑表单的异步请求
            await post('/api/index/setname', data, () => {
                ElMessage.success(`${data.username}数据的姓名已变更为${data.name}`)
            })
            // 更新 store 的状态
            const index = this.form.findIndex(item => item.id === this.editForm.id)
            this.form.splice(index, 1, Object.assign({}, this.editForm))
            //如果是当前账号，则更新store数据
            if(data.username==this.user.username){
                this.user.name = data.name
            }
        },
        async saveEditRole(data) {
            // 发起保存编辑表单的异步请求
            await post('/api/index/setrole', data, () => {
                ElMessage.success(`${data.username}数据的权限已变更为${data.newrole}`)
            })
            // 更新 store 的状态
            const index = this.form.findIndex(item => item.id === this.editForm.id)
            this.form.splice(index, 1, Object.assign({}, this.editForm))
            //如果是当前账号，则更新store数据
            if(data.username==this.user.username){
                this.user.newrole = data.newrole
            }
        },
        getuser(state){
            return state.user;
        }
    }
})

export default appuser